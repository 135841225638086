<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left required">From date:</label>
          <InputDatePicker v-model="date"></InputDatePicker>
          <label class="text-left required">Amount:</label>
          <select type="number" v-model="monthly">
            <option v-for="rate in monthlyRates" :key="rate">
              {{ rate }}
            </option>
          </select>
          <label class="text-left">Hourly Pay</label>
          <input type="number" v-model="hourly" disabled />
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
    <a class="heading-title-2">History</a>
    <Table
      :items="user ? user.pay : []"
      :defaultColumn="0"
      :columns="[
        {
          name: 'Date',
          sort: 'date',
          path: ['date'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Created By',
          sort: 'alphabet',
          path: ['createdBy', 'fullName'],
        },
        {
          name: 'Pay',
          sort: 'numeric',
          path: ['amount'],
          formatMap: (amount) =>
            `${Math.round(amount)} Kč/hod (${
              Math.round((amount * 8 * 252) / 12 / 1000) * 1000
            } Kč/měsíc)`,
        },
      ]"
    ></Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
const constants = require("@/constants.js");
import Table from "@/components/Table.vue";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      date: moment().toISOString(),
      monthly: constants.monthlyrates[0],
      monthlyRates: constants.monthlyrates,
      user: null,
    };
  },
  components: {
    Table,
    InputDatePicker,
  },
  computed: {
    hourly() {
      return (this.monthly * 12) / 252 / 8;
    },
  },
  methods: {
    ...mapActions(["addPay", "getUser"]),
    submit() {
      if (this.hourly > 0) {
        this.addPay({
          user: this.id,
          amount: this.hourly,
          date: this.date,
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
  },
  mounted() {
    this.getUser(this.id)
      .then((user) => {
        this.user = user;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.pay-row {
  grid-auto-columns: 1fr 1fr !important;
  gap: 10px;
}
</style>
